import {Component} from '@angular/core';

@Component({
    selector: 'spinner',
    standalone: true,
    imports: [

    ],
    templateUrl: './spinner.component.html',
})
export class SpinnerComponent {

    constructor(
    ) {
    }

}
